<template>
    <div class="rule">
      <p class="title">{{ac.activityName}}</p>
      <p class="time">活动周期：{{ac.gmtStartEffect}} - {{ac.gmtEndEffect}}</p>
      <p class="intro">活动简介：</p>
      <p>{{ac.activitySynopsis}}</p>
      <p class="intro">活动规则：</p>
      <p v-html="ac.activityContent" style="white-space: pre-wrap;">
      </p>
    </div>
</template>

<script>
import { formatDate, getQuery } from '../../common/util';
export default {
  components: {
    
  },
  data () {
      return {
        id: '',
        ac: {}
      }
  },
  mounted() {
    this.id = getQuery('id');
    this.getActivity();
  },
  methods: {
    getActivity() {
      this.$http.get('/api/activity/info', {

      })
      .then((res) => {
        this.ac = res.data.data;
        this.getData(this.ac.id);
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
  }

}
</script>

<style lang="less" scoped>
    .rule {
      padding: 0.3rem;
      box-sizing: border-box;
      color: white;
      min-height: 100vh;
      background-color: #79cdca;
      .title {
        font-size: 0.42rem;
      }
      .time {
        font-size: 0.26rem;
      }
      .intro {
        font-size: 0.3rem;
        margin-bottom: 0.3rem;
      }
      p {
        font-size: 0.28rem;
        margin-bottom: 0.3rem;
      }
    }
</style>
